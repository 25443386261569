import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private apiUrl = environment.api;

  constructor(private http: HttpClient, private navController: NavController) { }

  private setHeaders(): HttpHeaders {
    if (!localStorage.getItem('token')) {
      this.navController.navigateRoot('/entrar', { animated: true, animationDirection: 'back' });
    }
    const headersConfig = {
      'Content-Type': 'application/json',
      'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      'authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
    };
    return new HttpHeaders(headersConfig);
  }

  get<T>(path: string, responseType: 'json' | 'text' = 'json'): Observable<T> {
    const options = {
      headers: this.setHeaders(),
      responseType: responseType === 'text' ? 'text' as 'json' : 'json' as 'json'  // Definindo o tipo de resposta
    };
    return this.http.get<T>(`${this.apiUrl}${path}`, options);
  }

  post<T>(path: string, body: any): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}${path}`, body, { headers: this.setHeaders() });
  }

  put<T>(path: string, body: any): Observable<T> {
    return this.http.put<T>(`${this.apiUrl}${path}`, body, { headers: this.setHeaders() });
  }

  patch<T>(path: string): Observable<T> {
    return this.http.patch<T>(`${this.apiUrl}${path}`, { headers: this.setHeaders() });
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}${path}`, { headers: this.setHeaders() });
  }
}
