import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import * as fromRoot from '../../app.reducer';
import * as UI from '../../shared/store/ui.actions';
import {Router} from "@angular/router";
import {UIService} from "src/app/shared/store/ui.service";
import {Base64Service} from "src/app/shared/services/base64.service";
import {AlunoService} from "src/app/shared/services/aluno.service";
import {jwtDecode} from "jwt-decode";
import moment from "moment";
import {HttpService} from "src/app/shared/services/http.service";
import {NavController} from "@ionic/angular";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {MeusDadosPage} from "../meus-dados/meus-dados.page";

@Injectable({
    providedIn: "root",
})
export class AutenticacaoService {

    public _login: Subject<number> = new Subject<number>();

    constructor(
        private router: Router,
        private store: Store<fromRoot.State>,
        private uiService: UIService,
        private base64: Base64Service,
        private httpService: HttpService,
        private navController: NavController,
        private alunoService: AlunoService
    ) {
    }


    login(authData: any) {
        this.store.dispatch(new UI.StartLoading());

        let data = {
            username: this.base64.encode(authData.username),
            password: this.base64.encode(authData.senha)
        };

        this.httpService.post('v1/auth/login', data).subscribe(
            {
                next: (value): void => {
                    if (((value as any).access_token)) {
                        this.setTokenSession((value as any).access_token);
                        this.store.dispatch(new UI.StopLoading());
                        this.router.navigate(['tabs/home']);
                        this._login.next(1);
                    } else {
                        this.store.dispatch(new UI.StopLoading());
                        this.uiService.presentToast('bottom', (value as any).message);
                    }
                },
                error: (): void => {
                    this.store.dispatch(new UI.StopLoading());
                    this.store.dispatch(new UI.StopLoading());
                    this.router.navigate(['/entrar']);
                }
            }
        )

    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('turmaSelecionada');

        this.alunoService.limparDadosAluno();
        this.navController.navigateRoot('/entrar', {
            animated: true,
            animationDirection: 'back'
        });

        // window.location.reload();
    }

    get token(): any {
        let token = localStorage.getItem("token")?.replace('Bearer ', '');

        return token;
    }

    setTokenSession(token: string) {
        try {
            const payload = <JWTPayload>jwtDecode(token);
            const expiresAt = moment.unix(payload.exp);
            localStorage.setItem("token", "Bearer " + token);
            localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
            // valid token format
        } catch (error) {
            // invalid token format
            localStorage.removeItem('token');
            localStorage.removeItem('expires_at');
            console.error("Token Inválido");
            this.alunoService.limparDadosAluno();
            this.navController.navigateRoot('/entrar', {
                animated: true,
                animationDirection: 'back'
            });

        }
    }

    get usuarioId(): any {
        const payload = <JWTPayload>jwtDecode(this.token);
        return this.isLoggedIn() && payload ? payload.userId : null;
    }

    getExpiration() {
        const expiration = localStorage.getItem("expires_at");

        if (expiration) {
            const expiresAt = JSON.parse(expiration);
            return moment(expiresAt);
        } else {
            // Trate o caso em que 'expiresAt' não está definido
            return moment(0);
        }
    }

    isLoggedIn(): boolean {
        return this.token && moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getBearerToken() {
        return this.token.replace('Bearer ', '');
    }

    private setSession(authResult: any) {
        const token = authResult.get("authorization");
        const payload = <JWTPayload>jwtDecode(token);
        const expiresAt = moment.unix(payload.exp);
        localStorage.setItem("token", token);
        localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
    }

}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = localStorage.getItem("token");

        if (token) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", "" + token),
            });

            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}

interface JWTPayload {
    user_id: number;
    userId: string;
    username: string;
    exp: number;
}
