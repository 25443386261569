// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api: "https://api.nilopoliseduca.com.br/api/",
    aulas: "http://api.nilopoliseduca.com.br/",
    apiCms: "https://cms.multfacil.com.br/api/",
    team: "nilopolis",
    nometeam: "Nilópolis",
    nome_extenso: "App Nilópolis",
    theme: "nilopolis",
    rn_login: "",
    rn_cadastro: "",
    senderID: "",
    app_id: "com.nilopoliseduca.alunos",
    app_name: "Nilópolis Educa Estudante",
    app_description: "Aplicativo de Nilópolis.",
    app_url_applestore: "",
    app_url_googleplay: "",
    author_name: "Multifacil Soluções em Tecnologia",
    author_email: "",
    author_webSite: "",
    google_maps_key: "",
    politicaPrivacidade: "",
    versao: "1.2.0",
    bundle_id_ios: "",
    app_id_ios: "",
    idTeam: 5,
    assetImgUri: "nilopolis",
    mensagemOrientacaoLogin: "Se você tinha acesso ao app antigo, não será preciso fazer um <strong>novo cadastro.</strong>",
    keystorePath: "ni-app-portal-aluno-keystore.jks",
    keystoreAlias: "nilópolis",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
