// turma.service.ts
import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TurmaService {
    private readonly TURMA_STORAGE_KEY = 'turmaSelecionada';
    public turmaSelecionadaId: number | null = null;
    public _turmaSelecionada: Subject<number> = new Subject<number>();

    constructor() {
        const storedTurmaId = localStorage.getItem(this.TURMA_STORAGE_KEY);
        if (storedTurmaId) {
            this.turmaSelecionadaId = Number(storedTurmaId);
        }
    }

    getTurmaSelecionadaId(): number | null {
        return this.turmaSelecionadaId;
    }

    setTurmaSelecionadaId(id: number): void {
        this.turmaSelecionadaId = id;
        localStorage.setItem(this.TURMA_STORAGE_KEY, id.toString());
        this._turmaSelecionada.next(this.turmaSelecionadaId);
    }

    clearTurmaSelecionada(): void {
        this.turmaSelecionadaId = null;
        localStorage.removeItem(this.TURMA_STORAGE_KEY);
    }
}
