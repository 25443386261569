import {Observable, Subject, of} from "rxjs";
import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {TurmaService} from "./turma.service";

@Injectable({
    providedIn: "root",
})
export class AlunoService {

    public aluno: any;
    public _aluno: Subject<any> = new Subject<any>();
    public _selecionarTurma: Subject<any> = new Subject<any>();
    public _turmaSelecionada: Subject<any> = new Subject<any>();
    public isLoading: boolean = false;

    constructor(
        private httpService: HttpService,
        private turmaService: TurmaService
    ) {
    }

    getAluno(): Observable<any> {
        if (this.aluno) {
            return of(this.aluno);
        }
        return this.httpService.get('v2/app/aluno').pipe(
            tap(res => this.aluno = res),
        );
    }

    alterarSenha(data: any, alunoId: number) {
        return this.httpService.post(`v1/auth/alterarSenhaAluno/${alunoId}`, data);
    }

    limparDadosAluno() {
        this.aluno = null;
        this.turmaService.clearTurmaSelecionada();
    }

    getToken() {
        let token = localStorage.getItem('token');
        return token?.replace("Bearer", "");
    }

}
